var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"0"}},[_c('div',{staticStyle:{"padding":"12px 20px"}},[_c('XwSearch',{ref:"searchRef",attrs:{"form-config":_vm.searchConfig},on:{"search":_vm.search}})],1),_c('div',{staticStyle:{"padding":"0 20px"}},[(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":""}}):_vm._e(),_c('XwTable',{ref:"tableRef",attrs:{"columns":_vm.tableCols,"rows":_vm.pageList,"row-config":{height: 80}},scopedSlots:_vm._u([{key:"video",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"video-box"},[_c('div',{staticClass:"video-img"},[_c('v-img',{attrs:{"src":row.img,"width":"105","height":"59"}}),(row.duration)?_c('div',{staticClass:"video-duration"},[_vm._v(" "+_vm._s(_vm.convertSecondsToHMS(row.duration))+" ")]):_vm._e()],1),_c('div',{staticClass:"video-info ml-2"},[_c('div',{staticClass:"video-title",attrs:{"title":row.title}},[_vm._v(" "+_vm._s(row.title)+" ")]),_c('div',{staticClass:"video-size"},[_vm._v(" ID:"+_vm._s(row.videoId)+" ")])])])]}},{key:"time",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(" 源视频创建时间："+_vm._s(row.publishedAt || '-')),_c('br'),_vm._v(" 发布时间："+_vm._s(row.createdAt || '-')+" ")])]}},{key:"sourceChannelName",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"video-channel"},[_c('XwPlatform',{attrs:{"platform":row.sourcePlatform,"show-name":false}}),_vm._v(_vm._s(row.sourceChannelName)+" ")],1)]}},{key:"channelName",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"video-channel"},[_c('XwPlatform',{attrs:{"platform":row.publishPlatform,"show-name":false}}),_vm._v(_vm._s(row.publishChannelName)+" ")],1)]}},{key:"opts",fn:function(ref){
var row = ref.row;
return [_c('a',{on:{"click":function($event){return _vm.handleSetting(row)}}},[_vm._v(" 设置 ")])]}}])}),(_vm.pageLength)?_c('div',{staticStyle:{"padding":"20px 0"}},[_c('new-pagination',{attrs:{"page-index":_vm.page,"page-size":_vm.pageSize,"total":_vm.pageLength},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1):_vm._e()],1),(_vm.showSetting)?_c('Setting',{attrs:{"curr-item":_vm.currItem,"category-options":_vm.categoryOptions},on:{"success":_vm.handleRefresh},model:{value:(_vm.showSetting),callback:function ($$v) {_vm.showSetting=$$v},expression:"showSetting"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }